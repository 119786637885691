import React from 'react'
import { Link } from 'gatsby'
import Image from 'gatsby-plugin-sanity-image'
import Moment from 'react-moment'

export const PostGrid = ({ posts, showCategory, blog }) => {
    return (
        <div className={`grid ${blog ? 'grid-cols-12' : 'grid-cols-10'} lg:grid-cols-14`}>
            <div className={`col-start-2 ${blog ? 'col-end-12' : 'col-end-10'} lg:col-end-14 grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-16 lg:gap-y-24`}>
                {posts?.map((post, i) => {
                    const { date, heading, excerpt, image, slug, category } = post
                    return (
                        <div key={i} className="w-full">
                            <div className="overflow-hidden aspect-w-16 aspect-h-9 relative">
                                <Link to={`/${slug?.current}`}>{image && <Image className="transform hover:scale-105 duration-300 ease-in-out absolute top-0 left-0 w-full h-full object-cover" {...image} alt={excerpt} />}</Link>
                            </div>
                            <p className="text-sm md:text-lg font-helix-medium mt-5">
                                {showCategory ? <><span className="capitalize">{category?.slug?.current} - </span> <Moment withTitle format="D MMMM YYYY">{date}</Moment></>
                                    : <Moment withTitle format="D MMMM YYYY">{date}</Moment>}
                            </p>
                            <h3 className="text-xl md:text-4xl font-helix-bold md:font-helix-medium mt-2">{heading}</h3>
                            <p className="text-sm md:text-lg mt-4">{excerpt}</p>
                            <Link className="btn-navy text-sm md:text-base px-24 block mt-6" to={`/${slug?.current}`} aria-label={heading}>Read more</Link>
                        </div>
                    )
                })}
            </div>
        </div >
    )
}
