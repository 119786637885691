import { graphql, useStaticQuery } from "gatsby"

import { QueryFragments } from "./queryFragments" // eslint-disable-line

export const useLatestPosts = () => {
    const { allSanityBlog } = useStaticQuery(graphql`
    query {
        allSanityBlog(sort: {fields: [date], order: DESC}) {
            nodes {
                id
                date
                heading
                excerpt
                content:_rawContent(resolveReferences: {maxDepth: 10})
                slug {
                current
                }
                image{
                    ...ImageWithPreview
                }
                category{
                    slug{
                        current
                    }
                }
            }
        }
    }
  `)
    return allSanityBlog.nodes || []
}
