import React from "react";
import { graphql } from "gatsby";
import { PageMeta } from "@components/pageMeta";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Image from "gatsby-plugin-sanity-image";
import Layout from "@components/layout";
import { PortableTextBlock } from "@modules/portableTextBlock";
import { PostGrid } from "@modules/postGrid";

import { useLatestPosts } from "@querys/useLatestPosts";

const OtherBlogs = ({ ...blog }) => {
  const otherNews = useLatestPosts()
    .filter((b) => b.heading !== blog.heading)
    .slice(0, 2)
    .sort((a, b) => new Date(b.date) - new Date(a.date));
  return (
    <div className="bg-purple-100 pt-28 pb-32 mt-32">
      <div className="col-start-2 col-end-10 md:col-end-14 text-center text-2xl md:text-5xl">
        <h2 className="font-helix-medium leading-2xl md:leading-5xl">
          Other News
        </h2>
        <Link
          to="/news"
          className="font-helix text-lg leading-lg md:leading-xl md:text-xl font-bold underline"
        >
          View all
        </Link>
      </div>

      <div className="col-start-1 col-end-11 md:col-end-15 mt-16 md:mt-24">
        <PostGrid blog={true} posts={otherNews} />
      </div>
    </div>
  );
};

const Intro = ({ ...blog }) => {
  const { heading, date, content, image } = blog || {};
  return (
    <div className="grid grid-cols-14 mt-32 lg:mt-64 w-full media">
      <div className="col-start-1 col-end-15 lg:col-start-2 lg:col-end-14 text-center">
        <span className="text-xl leading-xl md:text-xl md:leading-xl font-helix-medium mx-gutter">
          {date}
        </span>
        <h1 className="lg:mt-4 md:text-5xl text-2xl leading-2xl md:leading-5xl font-helix-bold mx-gutter ">
          {heading}
        </h1>
        <div className="w-full h-auto mt-20 pb-10 lg:my-32 ">
          {image && <Image {...image} className="w-full h-auto px-gutter" />}
        </div>

        <div className="text-left prose md:prose-lg blog">
          <PortableTextBlock
            className="grid grid-cols-12 w-full"
            text={content}
          />
        </div>
      </div>
    </div>
  );
};

const Blog = ({ data: { blog } }) => {
  const { meta } = blog || {};
  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#1E1B43"></meta>
      </Helmet>
      <Layout bg={meta?.pageColor?.value}>
        <PageMeta {...meta} />
        <Intro {...blog} />
        <OtherBlogs {...blog} />
      </Layout>
    </>
  );
};

export default Blog;

export const blogQuery = graphql`
  query standardBlogQuery($slug: String!) {
    blog: sanityBlog(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
        pageColor {
          title
          value
        }
      }
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      date(locale: "NZ", formatString: "DD MMM YYYY")
      excerpt
      category {
        slug {
          current
        }
        title
      }
      slug {
        current
      }
      heading
      image {
        ...ImageWithPreview
      }
    }
  }
`;
